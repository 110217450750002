.progress {
	display: -ms-flexbox;
	display: flex;
	height: 1rem;
	overflow: hidden;
	font-size: 0.703125rem;
	background-color: #e2e2e9;
	border-radius: 3px;
	position: relative;
  }
  
  .progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: $white;
	text-align: center;
	white-space: nowrap;
	background-color: #e2e2e9;
	transition: width 0.6s ease;
  }
  
  .current-progressbar {
	margin-top: 14.5px;
  }
  
  .progress-content {
	margin-bottom: 16.5px;
  
	&:last-child {
	  margin-bottom: 0;
	}
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
	.progress-bar {
	  transition: none;
	}
  }
  
  .progress-bar-striped {
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
	background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
	-webkit-animation: progress-bar-stripes 1s linear infinite;
	animation: progress-bar-stripes 1s linear infinite;
  }
  
  .media {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: start;
	align-items: flex-start;
  }
  
  .media-body {
	-ms-flex: 1;
	flex: 1;
  }
  
  /*------ progress sizes ------*/
  
  .progress-xs {
	height: .25rem;
  
	.progress-bar {
	  height: .25rem;
	}
  }
  
  .progress-sm {
	height: .5rem;
  
	.progress-bar {
	  height: .5rem;
	}
  }
  
  .progress-lg {
	height: 1.25rem;
  
	.progress-bar {
	  height: 1.25rem;
	}
  }
  
  .progress-bar-indeterminate {
	&:after {
	  content: '';
	  position: absolute;
	  background-color: inherit;
	  left: 0;
	  will-change: left, right;
	  top: 0;
	  bottom: 0;
	}
  
	&:before {
	  content: '';
	  position: absolute;
	  background-color: inherit;
	  left: 0;
	  will-change: left, right;
	  top: 0;
	  bottom: 0;
	  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
	  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
	}
  
	&:after {
	  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
	  -webkit-animation-delay: 1.15s;
	  animation-delay: 1.15s;
	}
  }
  
  @-webkit-keyframes loader {
	from {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	to {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  
  @keyframes loader {
	from {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
  
	to {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }