/*----- Date Picker ----*/

.ui-datepicker {
	background-color: $white;
	border: 1px solid #e3e4e9;
	font-family: inherit;
	font-size: inherit;
	padding: 10px;
	margin: 0px 0 0;
	display: none;
	width: auto !important;
	z-index: 5 !important;
	box-shadow: $shadow;
	border-radius: 4px;
  
	.ui-datepicker-header {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  position: relative;
	  font-weight: 500;
	  font-size: 12px;
	  text-transform: uppercase;
	  color: #473b52;
	  padding: 0 0 5px;
	  letter-spacing: 1px;
	  border: 0;
	  background-color: transparent;
	  border-top-left-radius: 3px;
	  border-top-right-radius: 3px;
  
	  .ui-datepicker-next, .ui-datepicker-prev {
		text-indent: -99999px;
		color: #6c757d;
		top: 1px;
	  }
  
	  .ui-datepicker-next::before, .ui-datepicker-prev::before {
		font-size: 16px;
		font-family: 'FontAwesome';
		position: absolute;
		top: -4px;
		text-indent: 0;
	  }
  
	  .ui-datepicker-next {
		&:hover::before, &:focus::before {
		  color: $dark;
		}
	  }
  
	  .ui-datepicker-prev {
		&:hover::before, &:focus::before {
		  color: $dark;
		}
	  }
  
	  .ui-datepicker-next {
		order: 3;
  
		&:before {
		  right: 5px;
		  content: '\f105';
		}
	  }
  
	  .ui-datepicker-prev:before {
		left: 5px;
		content: '\f104';
	  }
  
	  .ui-datepicker-next-hover, .ui-datepicker-prev-hover {
		color: #495057;
		cursor: pointer;
		top: 1px;
		border: 0;
		background-color: transparent;
	  }
	}
  
	.ui-datepicker-calendar {
	  margin: 0;
	  background-color: transparent;
	  border-bottom-right-radius: 3px;
	  border-bottom-left-radius: 3px;
  
	  th {
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: 1px;
		padding: 6px 10px;
		color: #98a1b5;
	  }
  
	  td {
		border: 1px solid transparent;
		padding: 0;
		background-color: $white;
		text-align: right;
  
		&:last-child {
		  border-right: 0;
		}
  
		&.ui-datepicker-other-month .ui-state-default {
		  color: #ced1da;
		}
  
		span {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  background-color: $white;
		  color: $color;
		  padding: 8px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 50px;
		}
  
		a {
		  transition: all 0.2s ease-in-out;
		  padding: 5px;
		  background-color: $white;
		  color: $color;
		  padding: 8px 10px;
		  display: block;
		  font-weight: 400;
		  font-size: 12px;
		  border: 0;
		  border-radius: 50px;
  
		  &:hover {
			color: $white;
			box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		  }
		}
	  }
  
	  .ui-datepicker-today a {
		color: $white;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
	  }
	}
  }
  
  @media (max-width: 320px) {
	.ui-datepicker .ui-datepicker-calendar th {
	  padding: 4px 0;
	  letter-spacing: normal;
	}
  }
  
  .ui-datepicker-multi {
	.ui-datepicker-group {
	  padding-right: 15px;
	  width: auto;
	  float: left;
  
	  .ui-datepicker-title {
		margin: auto;
	  }
  
	  .ui-datepicker-prev::before {
		left: 10px;
	  }
  
	  .ui-datepicker-next::before {
		right: 10px;
	  }
  
	  table {
		margin: 0;
	  }
	}
  
	.ui-datepicker-group-last {
	  padding-right: 0;
	}
  }
  
  .ui-datepicker-inline {
	max-width: 270px;
	border-radius: 12px;
  }
  
  .model-wrapper-demo {
	padding: 50px 0;
	background: #f0f0f2;
  }
  
  .custom-checkbox {
	&:before {
	  position: absolute;
	  top: 0.25rem;
	  left: 0;
	  display: block;
	  width: 1rem;
	  height: 1rem;
	  pointer-events: none;
	  content: "";
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	  background-color: transparent;
	}
  
	&:after {
	  position: absolute;
	  top: 0.25rem;
	  left: 0;
	  display: block;
	  width: 1rem;
	  height: 1rem;
	  content: "";
	  background-repeat: no-repeat;
	  background-position: center center;
	  background-size: 50% 50%;
	}
  }
  
  .form-control.fc-datepicker {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
  }